import React from 'react';
import type { Organism } from '@verndale/core';
import globModules from './glob-modules';
import { renderElement } from '@storybook/react-dom-shim';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reactModule = (Component: any, nodeList: NodeListOf<HTMLElement>) => {
  return nodeList.forEach(node => {
    renderElement(<Component {...node.dataset} />, node);
  });
};

const modules: Organism[] = [
  {
    name: 'header',
    loader: () => import('./modules/header')
  },
  {
    name: 'search',
    loader: () => import('./components/search')
  },
  {
    name: 'navigation',
    loader: () => import('./components/navigation')
  },
  {
    name: 'paginator',
    loader: () => import('./components/paginator'),
  },
  {
    name: 'tile',
    loader: () => import('./components/tile')
  },
  {
    name: 'accordion',
    loader: () => import('./modules/accordion')
  },
  {
    name: 'sliderHeroBanner',
    loader: () => import('./components/slider-hero-banner'),
    styles: () => import('../scss/components/_slider-hero-banner.scss')
  },
  {
    name: 'mockApi',
    styles: () => import('../scss/modules/mock-api.scss'),
    loader: () => import('./modules/mockApi'),
    render: reactModule
  }
];

export default [...globModules, ...modules];
