import type { Organism } from '@verndale/core';
import { SliderProps } from './modules/content-slider';

const globModules: Organism[] = [
  {
    name: 'home-custom-animated-banner',
    loader: () => import('./modules/home-custom-animated-banner'),
    styles: () => import('../scss/modules/home-custom-animated-banner.scss')
  },
  {
    name: 'callout-hero-banner',
    loader: () => import('./modules/callout-hero-banner'),
    styles: () => import('../scss/modules/callout-hero-banner.scss')
  },
  {
    name: 'animated-title-hero-banner',
    loader: () => import('./modules/animated-title-hero-banner'),
    styles: () => import('../scss/modules/animated-title-hero-banner.scss')
  },
  {
    name: 'callout-two-column',
    loader: () => import('./modules/callout-two-column'),
    styles: () => import('../scss/modules/callout-two-column.scss')
  },
  {
    name: 'picture-grid',
    loader: () => import('./modules/picture-grid'),
    styles: () => import('../scss/modules/picture-grid.scss')
  },
  {
    name: 'content-slider',
    loader: () => import('./modules/content-slider'),
    styles: () => import('../scss/modules/content-slider.scss'),
    props: {
      intervalTimer: null,
      touchStartX: 0,
      touchEndX: 0
    } as SliderProps
  }
];

export default globModules;
